.waiting-list-view {
	/* background-color: white;
	margin-left: 10px;
	min-width: 370px; */
}
.header {
	padding: 10px 0;
	border-bottom: 1px solid #e9e9e9;
	margin-bottom: 20px;
}
.hamburgerIcon {
	height: 20px;
	align-self: center;
	margin-right: 10px;
}
.heading {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	align-self: center;
}
.waiting-card {
	border-top: 2px #7657f0 solid;
	background-color: rgba(127, 17, 224, 0.2);

	padding: 0 5px;
	margin-bottom: 10px;
}
.top-bar {
	padding: 5px 10px;
	border-bottom: 2px solid rgb(182, 180, 180);
}
.top-text {
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: black;
}
.card-body {
	padding: 5px 10px;
}
