@import "./misc.css";
@import "./input.css";
@import "./waitingList.css";
@import "./text.css";
@import "./rostering.css";
@import "./reports.css";

/* @import "./schedular.css"; */
body {
  font-family: "Work Sans", sans-serif !important;
  background-color: #f7f7f7;
}

.loginMain {
  background-image: url(../../../public/imgs/login.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

@media screen and (min-height: 700px) {
  .loginMain {
    height: 100vh;
  }
}

.loginMain .loginBox,
.forgetPassword .loginBox {
  background-color: #45454561;
  padding: 20px;
}

.loginMain .loginBox h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: white;
  text-align: center;
}

.loginMain .loginBox p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #b0b2b1;
  cursor: pointer;
  margin-bottom: 35px;
}

.loginMain .loginBox .remember {
  margin-top: 3px;
  margin-bottom: 0px;
}

.search-box {
  height: 150px;
  position: absolute;
  width: 240px;
  top: 83px;
  left: 10px;
  border: 1px solid gray;
  background-color: white;
  z-index: 1;
  overflow-y: scroll;
}

.search-box div {
  margin: 10px;
  cursor: pointer;
}

.search-group {
  height: 47px;
  overflow: hidden;
}

.search-group button {
  background-color: #3b668b;
  border-width: 0px;
}

.forgetPassword {
  background-image: url(../../../public/imgs/forgetPassword.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.zoom-kro {
  transform: scale(2);
  transition: all 0.5s;
}

.na-kro {
  transform: scale(1);
  transition: all 0.5s;
}

@media screen and (min-height: 700px) {
  .forgetPassword {
    height: 100vh;
  }
}

.forgetPassword .loginBox h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: white;
  text-align: center;
}

.forgetPassword .loginBox p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #b0b2b1;
  cursor: pointer;
  margin-bottom: 35px;
}

.forgetPassword .loginBox .remember {
  margin-top: 3px;
  margin-bottom: 0px;
}

.loginBtn {
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: white;
  width: 100%;
  height: 51px;
  background: linear-gradient(180deg, #295381 0%, #274461 100%);
  border-radius: 16.3768px;
}

.loginBtn:hover {
  color: white !important;
  border: 1px solid white !important;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 15px;
  color: white;
  margin-bottom: 15px !important;
}

input.form-control {
  border: 1px solid #939393 !important;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
  background: white;
}

input.form-control::-moz-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
}

input.form-control:-ms-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
}

input.form-control::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #747474;
}

input.form-control:focus {
  box-shadow: none !important;
}

.input-group {
  border: 1px solid #939393 !important;
  border-radius: 8px;
  padding: 5px 0px;
  background: white;
}

.input-group .input-group-text {
  background: transparent;
  border: none;
}

.input-group input {
  border: none !important;
}

.input-group .btn {
  z-index: 0 !important;
}
.disabled-input {
  background-color: #eee;
}

.disabled-input .select-async > div > div {
  background-color: #eee;
  pointer-events: none;
}

.date-disabled-input {
  background-color: #eee;
  pointer-events: none;
}

.date-disabled-input div, .date-disabled-input input {
  background-color: #eee;
}

textarea {
  border: none !important;
}

textarea:focus {
  box-shadow: none !important;
}

.form-select {
  border: 1px solid #939393 !important;
  border-radius: 8px;
  padding: 11px 12px;
  color: #747474;
}

.form-select:focus {
  box-shadow: none !important;
}

.addItem {
  background-color: #295381;
  color: white;
  border: none;
  height: 40px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
}

.addItem-billing {
  background-color: #295381;
  color: white;
  border: none;
  height: 30px;
  /* padding: 0px 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 180px;
  border-radius: 8px;

  /* font-weight: bold; */
}

.wd-150 {
  width: 150px !important;
}

.wd-100 {
  width: 100px !important;
}

.addItem-new-pat {
  background-color: #295381;
  color: white;
  border: none;
  /* height: 40px; */
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  border-radius: 0.375rem;
}

.memo-save-button {
  background-color: #295381;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  margin-left: auto;
}

.addItem:hover {
  background-color: #295381 !important;
}

@media screen and (max-width: 575px) {
  .addItem {
    font-size: 15px;
    padding: 10px;
  }
}

.mainHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.mainHeading h1 {
  font-size: 23px;
  margin-bottom: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .mainHeading h1 {
    font-size: 18px;
  }
}

.mainHeading h1 .fa-arrow-left {
  margin-right: 10px;
  cursor: pointer;
}

.addEdit {
  padding: 30px 25px;
  background-color: white;
}

.addEdit label {
  color: black;
  font-weight: bold;
  font-size: 19px;
  line-height: 21px;
}

.addEdit .addItem {
  height: 50px;
  width: 190px;
  font-weight: bold;
  background: linear-gradient(180deg, #3d6a94 0%, #32556a 100%);
}

.grayBack {
  background-color: #f8f8f8;
  padding: 25px;
  margin-bottom: 1rem;
}

.grayBack .grayLabel {
  color: #939393;
}

.grayBack .boxTitle span {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  color: #939393;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

.grayBack .boxTitle span::before {
  content: "+";
}

.grayBack .boxTitleColor span {
  background: #274461;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  color: #939393;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

.grayBack .boxTitleColor span::before {
  content: "-";
}

.grayBack .checkBox .form-check {
  display: flex;
  align-items: center;
}

.grayBack .checkBox .form-check .form-check-input {
  background-color: transparent;
  border: 1.4px solid #939393;
}

.grayBack .checkBox .form-check .form-check-input:checked {
  background-color: gray;
}

.grayBack .checkBox .form-check .form-check-input:focus {
  box-shadow: none !important;
}

.grayBack .checkBox .form-check label {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #242424;
  margin-top: 3px !important;
  margin-left: 10px !important;
}

.grayBack .checkBoxChild {
  margin-left: 29px;
}

.grayBack .checkBoxChild .form-check {
  display: flex;
  align-items: center;
}

.grayBack .checkBoxChild .form-check .form-check-input {
  background-color: transparent;
  border: 1.4px solid #939393;
}

.grayBack .checkBoxChild .form-check .form-check-input:checked {
  background-color: gray;
}

.grayBack .checkBoxChild .form-check .form-check-input:focus {
  box-shadow: none !important;
}

.grayBack .checkBoxChild .form-check label {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #242424;
  margin-top: 3px !important;
  margin-left: 10px !important;
}

.multi-select {
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 100px;
  border: 2px solid #236384;
  border-radius: 50px;
  cursor: pointer;
}

.my-add-button {
  width: 150px;
  background-color: #62dea3;
  border-radius: 50px;
  border-width: 0px;
}

.multi-roles-parent {
  background-color: #aebbc5;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  position: relative;
  color: white;
}

.multi-roles-child {
  position: absolute;
  right: -8px;
  top: -3px;
  background-color: #386080;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.upload-button {
  width: 120px;
  margin-right: 30px;
  background-color: #62dea3;
  border-radius: 50px;
  border-width: 0px;
}

.preview-btn {
  background-color: #365c78;
  color: white;
  border: 0px;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-button {
  width: 120px;
  /* margin-right: 30px; */
  /* background-color: #62dea3; */
  border-radius: 50px;
  border-width: 0px;
}

.color-div {
  height: 50px;
  width: 50px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.my-save-button {
  width: 130px;
  background-color: #24566f;
  border-radius: 5px;
  border-width: 0px;
}

.tabContent .scrollBar2 .document .icons .fa-trash {
  color: #e7653d;
  cursor: pointer;
}

.questionnaire-button {
  height: 40px;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  border-width: 0px;
  background-color: #085481;
  color: white;
  border-radius: 15px;
}

.copy-icon {
  height: 40px;
  display: flex;
  align-items: center;
}

.fa-copy {
  transform: scale(1.5);
}

.overlay-container {
  border: 2px solid gray;
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
}

.subMenu p {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 30px;
  color: #34556f;
}

.sub-heading {
  font-size: 24px;
  font-weight: bold;
  color: #939393;
}

.subheading {
  color: #939393;
  font-size: 22px;
  font-weight: bold;
}

.add-button {
  border-radius: 50px !important;
  margin: 5px;
  background-color: #226181;
}

.plus-icon {
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32);
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 5px;
  background-color: #274461;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.minus-icon {
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.large-text-area {
  height: 100px;
}

.larger-text-area {
  height: 130px;
}

.custom-input {
  border-radius: 5px;
  border-width: 1px;
  border-color: gray;
}

.fa-bars {
  cursor: pointer;
  transition: all 0.5s ease-in;
  margin-left: 45px;
  font-size: 19px;
}

.fa-bars.openSidebar {
  left: 260px;
}

/* @media screen and (max-width: 600px) {
  .fa-bars {
    margin-left: 70px !important;
  }
} */

.font-blue {
  color: #0d6efd !important;
  font-weight: 500;
}

.font-red {
  color: red !important;
  font-weight: 500;
}

.topHead {
  position: fixed;
  width: 100%;
  z-index: 9;
  background: white;
}

.font-12 {
  font-size: 12px !important;
}

.small-button-1 {
  font-size: 14px;
  background-color: #085481;
  border-width: 0px;
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
}

.small-button-2 {
  font-size: 14px;
  border-width: 0px;
  color: gray;
  padding: 10px 30px;
  border-radius: 10px;
}

#upload-photo {
  display: none;
}

.topHead .topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0px;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .logo-toggle img {
    width: 85px;
  }

  .topHead .topSection .logo-toggle .fa-bars {
    margin-left: 10px;
  }
}

.topHead .topSection .userProfile {
  display: flex;
  align-items: center;
}

.topHead .topSection .userProfile .fa-bell {
  font-size: 23px;
  padding-bottom: 5px;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .userProfile .fa-bell {
    font-size: 15px;
  }
}

.topHead .topSection .userProfile .line {
  width: 1px;
  height: 30px;
  background-color: #e0e0e0;
  margin: 0px 10px;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .userProfile .line {
    margin: 0px 7px;
  }
}

.topHead .topSection .userProfile .name h5 {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #242424;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .userProfile .name h5 {
    font-size: 13px;
    line-height: 15px;
    width: 55px;
  }
}

.topHead .topSection .userProfile .name p {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #939393;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .userProfile .name p {
    font-size: 10px;
    line-height: 15px;
  }
}

.topHead .topSection .userProfile .userImg {
  margin: 0px 10px;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .userProfile .userImg {
    margin: 0px 5px;
  }
}

.topHead .topSection .userProfile .userImg img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (max-width: 575px) {
  .topHead .topSection .userProfile .userImg img {
    width: 40px;
    height: 40px;
  }
}

.topHead .topSection .userProfile .dropdown button {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  background: #f0ebff;
  border-radius: 6px;
}

.form-check-input:checked[type="radio"] {
  background-color: #34556f !important;
  background-image: url(../images/radio-border.png);
}

.form-check-input:checked {
  border-width: 0px;
}

label.myLabel input[type="file"] {
  display: none;
}

.myLabel {
  display: inline-block;
}

.form-check-input:checked {
  background-color: #34556f !important;
}

.myCal {
  height: 50px;
  width: 100%;
  font-size: 16px;
}

.calClass {
  height: 320px;
}

.react-date-picker__wrapper {
  border-radius: 15px;
  padding-left: 10px;
}

.react-date-picker__inputGroup__divider {
  display: none;
}

.sideBar {
  position: fixed;
  background: #34556f;
  width: 250px;
  height: 100%;
  margin-left: 0;
  padding-top: 15px;
  transition: all 0.5s ease-in;
  margin-top: 92px;
  z-index: 10;
  padding-bottom: 60px;
}

.sideBar ul {
  padding-left: 0px;
}

.sideBar ul a {
  text-decoration: none;
}

.sideBar ul i {
  width: 20px;
  margin-right: 10px;
  margin-top: -3px;
  color: white;
}

.sideBar ul li {
  list-style: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  padding: 20px 15px;
  color: white;
  display: flex;
  /* align-items: baseline; */
  align-items: center;
}

.sideBar ul li.active {
  color: #34556f;
  background: white;
  padding: 20px 15px;
  font-weight: bold;
  align-items: center;
}

.sideBar ul li.active i {
  color: #34556f;
}

.editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 10px;
  z-index: 2;
}

.editor-icon-active {
  background-color: white;
}

.editor-icon-inactive {
  background-color: #085481;
}

.editor-icon-active i {
  color: #085481;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(1.5);
}

.rev-table .rdt_Table {
  direction: rtl;
}

.rev-table .rdt_TableCell,
.rdt_TableCol {
  direction: ltr;
}

.expand-icon {
  width: 25px;
}

.plus-sign {
  height: 20px;
  width: 20px;
  background-color: #939393;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.w-180 {
  width: 180px;
}

.h-50 {
  height: 50px;
}

.cw-div {
  width: 100%;
  display: flex;
  direction: ltr;
  align-items: center;
  height: 50px;
}

.light-gray-background {
  background-color: #f8f8f8;
}

.cw-160 {
  text-align: center;
  width: 160px;
}

.cw-170 {
  text-align: center;
  width: 170px;
}

.cw-180 {
  text-align: center;
  width: 180px;
}

.cw-200 {
  text-align: center;
  width: 200px;
}

.custom-table-head {
  background: #34556f;
  display: flex;
  color: white;
  height: 50px;
  align-items: center;
  width: 1350px;
}

.custom-table-head p {
  margin: 0px !important;
  padding: 0px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-table-head-2 {
  background: #34556f;
  display: flex;
  color: white;
  height: 50px;
  align-items: center;
  width: 700px;
}

.custom-table-head-2 p {
  margin: 0px !important;
  padding: 0px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-table-cells {
  display: flex;
  align-items: center;
  height: 50px;
  width: 1350px;
}

.custom-table-cells:nth-child(odd) {
  background-color: #f8f8f8;
}

.custom-table-cells p {
  margin: 0px !important;
  padding: 0px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-table-cells-2 {
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 700px;
}

.custom-table-cells-2:nth-child(odd) {
  background-color: #f8f8f8;
}

.custom-table-cells-2 p {
  margin: 0px !important;
  padding: 0px !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.searchbox-packages {
  position: absolute;
  z-index: 3;
  border: 1px solid gray;
  padding: 10px;
  background-color: white;
  width: -webkit-fill-available;
  overflow-y: auto;
  max-height: 400px;
}

.searchbox-billing {
  position: absolute;
  z-index: 3;
  border: 1px solid gray;
  padding: 10px;
  background-color: white;
  width: 500px;
  height: 400px;
  overflow: scroll;
  /* width: 100%; */
}

.w-500 {
  width: 500px;
}

.refund-info {
  background-color: #f8f8f8;
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  align-items: center;
}

.searchbox-packages div {
  cursor: pointer;
  padding: 5px 0px;
}

.searchbox-stock {
  position: absolute;
  z-index: 3;
  border: 1px solid gray;
  padding: 10px;
  background-color: white;
  overflow-y: auto;
  max-height: 500px;
  /* width: auto; */
}

.searchbox-stock div {
  cursor: pointer;
  padding: 5px 0px;
}

.editor-icon-inactive i {
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(1.5);
}

.textbox-class {
  position: absolute;
  top: 110px;
  left: 60px;
}

.box1-zoomin {
  transform: scale(2) !important;
  transition: all 0.5s;
}

.box1-zoomout {
  transform: scale(1) !important;
  transition: all 0.5s;
}

.marker-extra-zoomin {
  transform: scale(2);
  transition: all 0.5s;
}

.marker-extra-zoomout {
  transform: none;
  transition: all 0.5s;
}

.drawtool-marker {
  height: 10px;
  width: 10px;
  background: red;
  border-radius: 50%;
}

.innerPage {
  padding-top: 92px !important;
}

@media screen and (max-width: 575px) {
  .innerPage {
    padding-top: 78px !important;
  }
}

.prod-box {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.round-button {
  background-color: #62dea3;
  border-radius: 50px;
  color: white;
  width: 150px;
  border-width: 0px;
  height: 35px;
}

.active-status {
  background-color: #65de62;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
}

.ongoing-status {
  background-color: #dea562;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
}

.inactive-status {
  background-color: #de6a62;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  border-radius: 50px;
}

.conversion-status {
  background-color: #1c7a89;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  border-radius: 50px;
}

.finalized-status {
  background-color: #65de62;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
}

.draft-status {
  background-color: #dea562;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
}

.active-received {
  background-color: #65de62;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
}

.inactive-received {
  background-color: #62dea3;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
}

.active-finalized {
  background-color: #65de62;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
  cursor: not-allowed !important;
}

.inactive-finalized {
  background-color: #dea562;
  height: 30px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  color: white;
  cursor: pointer !important;
}

.closeSidebar {
  margin-left: -250px;
}

.total-font {
  color: #939393;
  margin-top: 20px;
}

.gray-font {
  color: #939393 !important;
}

.shadow-text-box {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  align-items: center;
  border-radius: 4px;
  height: 50px;
  padding: 5px 20px 0px 20px;
}

.shadow-text-box div {
  font-size: 16px;
}

.shadow-text-box span {
  font-weight: bold;
  font-size: 16px;
}

.box-margin {
  margin-top: 80px;
  background-color: #f8f8f8;
}

.shadow-box-2 {
  height: 40px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 0px 20px;
  margin-bottom: 10px;
}

.shadow-box-2 .a {
  font-weight: bold;
}

.shadow-box-2 .b {
  font-weight: bold;
}

.sidePage {
  margin-left: 250px;
}

.bold {
  font-weight: bold;
}

.unbold {
  font-weight: 500 !important;
}

.patient-history {
  font-size: 18px;
  padding-left: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e9e9e9;
}

.memo-button {
  background-color: #939393;
  border-radius: 50px;
  border-width: 0px;
  font-size: 14px;
}

.flag-check {
  color: black;
  margin-left: 10px !important;
  font-size: 14px;
}

.icon-width {
  width: 50px !important;
  display: flex;
  justify-content: center;
}

.icon-width-2 {
  width: 30px !important;
  display: flex;
  justify-content: center;
}

.form-control:disabled {
  background-color: transparent;
}

.custom-calendar {
  height: 270px;
}

.questionnaire-col {
  padding: 0px;
}

.question-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
}

.question-header {
  /* min-height: 50px; */
  /* font-size:20px; */
  font-size: 16px;
  color: inherit;
  display: flex;
  align-items: center;
}

.font-black {
  color: black;
}

.form-check-label {
  color: black;
  margin-left: 10px;
}

.membership-headings {
  font-weight: bold;
  font-size: 19px;
  line-height: 21px;
}

.white-background {
  background-color: white !important;
}

.color-white {
  color: white !important;
}

.abc .form-check-label {
  margin-right: 5px !important;
}

.abc .form-check {
  display: flex;
  /* align-items: center; */
}

.detail-box {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 5px 20px;
  margin-bottom: 5px;
}

.detail-box :first-child {
  font-weight: bold;
  font-size: 17px;
}

.detail-box :nth-child(2) {
  font-size: 17px;
}

.char-limit {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 14px;
  color: gray;
}

.question-input {
  border: 1px solid gray !important;
  resize: none;
  border-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.alert-heading {
  font-weight: bolder;
  font-size: 22px;
}

.questionnaire-heading {
  font-weight: bolder;
  font-size: 26px;
}

.history {
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 20px;
}

.sidePage.openSidebar {
  margin-left: 250px;
  transition: all 0.5s ease-in;
  background: #f7f7f7;
}

.delete-yes {
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  background-color: #f2625a;
}

.delete-no {
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
  border-width: 0px;
  justify-content: center;
  background-color: #24566f;
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.custom-scroll {
  overflow-y: scroll;
}

.custom-scroll::-webkit-scrollbar {
  display: none;
}

.nurse-notes-container {
  height: 500px;
  overflow-y: scroll;
  padding-right: 20px;
}

.flag {
  width: 20px;
}

.nurse-notes-container::-webkit-scrollbar {
  width: 6px;
}

.nurse-notes-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px gray;
  border-radius: 10px;
}

.nurse-notes-container::-webkit-scrollbar-thumb {
  background: #24566f;
  border-radius: 10px;
}

.nurse-accordion-header .accordion-button {
  background-color: #f1f1f1;
  color: black;
  font-weight: bold;
}

.session-accordion-header .accordion-button {
  background-color: #f1f1f1;
  color: black;
  outline: 0;
  box-shadow: none !important;
}

/* 
.rc-textarea{
  padding: 10px;
  border-radius: 10px;
} */

.color-blue {
  color: #3253ca;
}

.color-black {
  color: black;
}

.sidePage.closeSidebar {
  margin-left: 0px;
  transition: all 0.5s ease-in;
  background-color: #f7f7f7;
}

.total-cont {
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 300px;
}

.breadCrumb h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.gJhVev span {
  opacity: 1 !important;
}

.rdt_TableHeadRow {
  background: #34556f !important;
  color: white;
  font-size: 15px;
}

.rdt_TableRow {
  font-size: 15px !important;
}

.rdt_Pagination {
  font-size: 15px !important;
}

.visit-hist-buttons {
  background-color: #295381;
  color: white;
  border: none;
  height: 40px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.addSecondSidebar {
  /* width: 325px !important; */
  padding-right: 1.5rem !important;
}

.sidebarExtend {
  color: white;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.gray-background {
  background-color: #e9e9e9;
}

.addSecondSidebar .patientImg {
  background: #ffffff;
  padding: 15px;
  margin-top: 70px;
  text-align: center;
}

.addSecondSidebar .patientImg p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #939393;
  margin: 0;
}

/* .addSecondSidebar .patientImg img {
  width: 50px;
  height: 50px;
  object-fit: contain;
} */

.dp-div {
  border: 4px solid #939393;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.addSecondSidebar .patientImg .name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #34556f;
  margin: 0;
}

.addSecondSidebar .alertBtn {
  text-align: center;
  margin: 15px;
}

.addSecondSidebar .alertBtn button {
  background: #62dea3;
  border-color: #62dea3;
  border-radius: 10px;
}

.addSecondSidebar .alertBtn button:hover {
  background: #62dea3;
  border-color: #62dea3;
}

.addSecondSidebar .addressDetail {
  background: #ffffff;
  padding: 10px 15px;
}

.addSecondSidebar .addressDetail .detailPadding {
  padding: 5px 0px;
  display: flex;
}

.addSecondSidebar .addressDetail .detailPadding i {
  color: #34556f;
  line-height: 22px;
  margin-right: 10px;
  font-size: 18px;
}

.addSecondSidebar .addressDetail .detailPadding h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: start;
  width: 100%;
  color: #242424;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.addSecondSidebar .patientInfo {
  background-color: white;
  padding: 10px 0px;
  margin-top: 15px;
}

.addSecondSidebar .patientInfo .sideBars {
  position: relative;
  background: white;
  height: 100%;
  margin-left: 0;
  padding-top: 15px;
  transition: all 0.5s ease-in;
}

.addSecondSidebar .patientInfo .sideBars ul {
  padding-left: 0px;
}

.addSecondSidebar .patientInfo .sideBars ul a {
  text-decoration: none;
}

.addSecondSidebar .patientInfo .sideBars ul i {
  width: 20px;
  margin-right: 10px;
  margin-top: -3px;
  color: #939393;
}

.addSecondSidebar .patientInfo .sideBars ul li {
  list-style: none;
  padding: 15px 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  padding: 20px 15px;
  color: #34556f;
}

.addSecondSidebar .patientInfo .sideBars ul li.active {
  color: white;
  background: #34556f;
  padding: 15px 15px;
}

.addSecondSidebar .patientInfo .sideBars ul li.active i {
  color: white;
}

.addSecondSidebar .patientInfo .innerPage {
  padding-top: 92px !important;
}

@media screen and (max-width: 575px) {
  .addSecondSidebar .patientInfo .innerPage {
    padding-top: 78px !important;
  }
}

.fa-trash {
  color: #e7653d !important;
}

.fa-pen {
  color: #e7c13d;
}

.fa-arrow-down-short-wide {
  color: #939393;
}

.fa-circle-xmark {
  color: #e7653d !important;
}

.fa-circle-check {
  color: #65de62 !important;
}

.mainTab {
  background-color: white;
}

.mainTab .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e9e9e9;
  padding: 15px;
}

.mainTab .header h4 {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #939393;
}

.mainTab .header .addBtn {
  background-color: #295381;
  color: white;
  border: none;
  height: 33px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
}

.mainTab .header .addBtn:hover {
  background-color: #295381 !important;
}

@media screen and (max-width: 575px) {
  .mainTab .header .addBtn {
    font-size: 15px;
    padding: 10px;
  }
}

.mainTab .scrollBar {
  height: 500px;
  overflow-y: scroll;
}

.mainTab .scrollBar .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e9e9e9;
  padding: 15px;
  background: transparent;
  color: black;
  border-radius: 0px;
}

.mainTab .scrollBar .nav-item .nav-link:hover {
  background-color: #eaeaff;
}

.mainTab .scrollBar .nav-item .nav-link .innerLink {
  display: flex;
  align-items: center;
}

.mainTab .scrollBar .nav-item .nav-link .innerLink img {
  width: 50px;
}

.mainTab .scrollBar .nav-item .nav-link .innerLink p {
  margin-bottom: 0px;
  margin-left: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f434a;
}

.mainTab .scrollBar .nav-item .nav-link .icons .fa-pen {
  color: #e7c13d;
}

.mainTab .scrollBar .nav-item .nav-link .icons .line {
  border: 1px solid #e9e9e9;
  margin: 0px 15px;
}

.mainTab .scrollBar .nav-item .nav-link .icons .fa-trash {
  color: #e7653d;
}

.tabContent {
  background-color: white;
}

.tabContent .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e9e9e9;
  padding: 15px;
}

.tabContent .header h4 {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #939393;
}

.tabContent .header .wrapper {
  display: inline-block;
  position: relative;
}

.tabContent .header input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tabContent .header .addBtn {
  background-color: #295381;
  color: white;
  border: none;
  height: 33px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
}

.tabContent .header .addBtn:hover {
  background-color: #295381 !important;
}

@media screen and (max-width: 575px) {
  .tabContent .header .addBtn {
    font-size: 15px;
    padding: 10px;
  }
}

.tabContent .header .addBtn img {
  width: 13px;
  margin-right: 10px;
}

.tabContent .scrollBar2 {
  height: 500px;
  overflow-y: scroll;
}

.tabContent .scrollBar2 .document {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e9e9e9;
  padding: 15px;
  background: transparent;
  color: black;
  border-radius: 0px;
}

.tabContent .scrollBar2 .document .innerLink {
  display: flex;
  align-items: center;
}

.tabContent .scrollBar2 .document .innerLink img {
  width: 50px;
}

.tabContent .scrollBar2 .document .innerLink h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #3f434a;
  margin-bottom: 1px;
  margin-left: 10px;
}

.tabContent .scrollBar2 .document .innerLink p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3f434a;
}

.tabContent .scrollBar2 .document .icons .fa-pen {
  color: #e7c13d;
  cursor: pointer;
}

.tabContent .scrollBar2 .document .icons .line {
  border: 1px solid #e9e9e9;
  margin: 0px 15px;
}

.tabContent .scrollBar2 .document .icons .fa-trash {
  color: #e7653d;
  cursor: pointer;
}

.drawtool-images {
  max-width: 160px;
  /* height:auto; */
  max-height: 180px;
  cursor: pointer;
  object-fit: contain;
}

.image-container-1 {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-items: center;
  overflow-y: hidden;
  /* max-height: 220px; */
  /* justify-content: center; */
}

.jVeafb:not(:last-of-type) {
  border-width: 0px !important;
}

.hjBMSB:not(:last-of-type) {
  border-width: 0px !important;
}

.sidebar-subcategory {
  color: #34556f;
  font-weight: bold;
  font-size: 14px;
}

.pt-icon {
  width: 20px;
  margin-right: 10px;
  /* height: 30px; */
}

.btn-close {
  font-size: 12px;
}

.patientModal {
  padding: 25px;
  padding-top: 5px;
}

.patientModal .alert-heading {
  font-size: 20px;
  font-weight: bold;
}

.patientModal .bold {
  font-weight: bold;
}

.patientModal .memo-button {
  background-color: #295381;
  color: white;
  border: none;
  height: 36px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
}

.patientModal .memo-button:hover {
  background-color: #295381 !important;
}

@media screen and (max-width: 575px) {
  .patientModal .memo-button {
    font-size: 15px;
    padding: 10px;
  }
}

.patientModal .flag-check {
  color: #242424;
  font-size: 13px;
  margin-left: 5px !important;
}

.add-button {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #34556f;
  border: none;
  padding: 8px 15px;
}

.form-check-input:checked {
  background-color: #34556f;
  border-color: #34556f;
}

.schedularModal .modal-dialog {
  max-width: 1300px;
  z-index: 9999;
}

.schedularModal .mainHeading {
  display: none;
}

.schedularModal.waitingModal .col-md-6 {
  width: 100%;
}

.schedularModal.waitingModal .w-75 {
  width: 100% !important;
}

.schedularModal.waitingModal .modal-dialog {
  max-width: 600px;
}

.schedularModal .modal-content {
  background-color: transparent;
  border: 0;
}

.schedularModal .modal-footer {
  display: none;
}

.schedularModal .modal-body {
  padding: 0;
}

.patient-card {
  background-color: #ffff;
  padding: 15px;
  border-radius: 7px;
}

.patient-card .btn-primary {
  width: 150px;
  background-color: #62dea3;
  border-radius: 8px;
  color: #ffff;
  border-width: 0px;
}

.patient-card p {
  text-transform: capitalize;
  font-size: 16px;
}

.patient-card .name {
  font-size: 18px;
  color: #34556f;
  margin-bottom: 20px;
  font-weight: 600;
}

.patient-card .detailPadding {
  padding: 15px 0;
}

.patient-card .detailPadding i {
  color: #34556f;
  margin-top: 3px;
}

.patient-card .detailPadding h6 {
  margin-bottom: 0;
}

.patient-card .appoint {
  font-weight: bold;
  margin-bottom: 15px;
}

.pre_appointment {
  background-color: #ffff;
  padding: 15px;
  border-radius: 7px;
  margin-top: 10px;
}

.pre_appointment .appoint {
  font-weight: bold;
  margin-bottom: 15px;
}

.patientDetails {
  background-color: #ffff;
  border-radius: 7px;
}

.innerPage .container {
  max-width: 100%;
  padding: 0px 25px !important;
}

.pdf-btn a {
  background-color: #295381;
  color: white;
  border: none;
  height: 40px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  margin: 3rem;
  border-radius: 5px;
}

.pdf-btn a:hover {
  color: white;
}

.print-color {
  color: #3f51b5;
}

.hide-btn .FIE_topbar-save-button {
  display: none;
}
.filter-wrapper {
  margin-right: -0.5rem !important;
}
.filter-wrapper .col-3 > button {
  margin-top: 28px;
}
.filter-wrapper .col-3.aplybtn {
  width: auto;
}

.rounded-button {
  height: auto !important;
  color: black;
}

.specialDay {
  background-color: rgb(241, 196, 15) !important;
  color: white !important;
}

.specialDay.Mui-selected {
  background-color: #1565c0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.dx-scheduler-appointment {
  left: 5px;
}

.rostering-search input::-webkit-input-placeholder,
.rostering-search input::-moz-placeholder,
.rostering-search input:-ms-input-placeholder,
.rostering-search input::-ms-input-placeholder,
.rostering-search input::placeholder {
  font-size: 14px;
}

.dx-scheduler-date-table-row,
.dx-scheduler-cell-sizes-vertical,
.dx-scheduler-time-panel-row {
  height: 40px !important;
}

.dx-scheduler-cell-sizes-horizontal {
  width: 100% !important;
}

.dx-scheduler-header-panel {
  width: auto !important;
}

/* .dx-scheduler-group-header,
.dx-scheduler-cell-sizes-horizontal {
  width: 500px !important;
} */

.dx-item:hover .rostering-icon {
  display: flex !important;
}

.dx-state-hover {
  z-index: 100;
}

/* .dx-item.dx-scheduler-appointment.dx-scheduler-appointment-vertical {
  min-width: 227px;
} */

.showtime-preview {
  width: 90%;
  white-space: break-spaces;
}

.record-box {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  background-color: #ddd;
  cursor: pointer;
  border-radius: 5px;
}

.record-box i {
  float: right;
  position: relative;
  top: 8px;
}

.active-folder {
  background-color: #eaeaff;
}

.billing-data-table {
  max-height: 1000px;
  overflow: scroll;
}

@media only screen and (max-width: 900px) {
  .sidePage.openSidebar,
  .sidePage.closeSidebar {
    width: fit-content !important;
  }
  .addItem {
    font-size: 14px;
    width: 180px;
  }
  .mainHeading h1 {
    margin-right: 10px;
  }
  .form-check .form-check-label {
    line-height: 24px;
  }
  .signature-btn {
    height: 350px;
    margin-top: 25px;
  }
  .responsive-btn {
    min-width: 200px;
    height: 55px;
  }
  .modal-dialog-centered {
    min-height: auto;
  }
  .patient-wrapper {
    width: 900px;
  }
}
.disabled-style {
  background-color: #f2f2f2 !important;
}
.wrapper-table-report {
  height: 800px;
  overflow-y: auto;
}
.attendance-wrapper {
  background-color: #34556f;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}

.attendance-wrapper img {
  width: 45px;
  padding: 5px;
}

.attendance-wrapper:hover {
  background-color: #213e55;
}

.med-info-box {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border: 1px solid #ddd;
}

.half-column-box {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
}

.font-16 {
  font-size: 16px;
}

.grid-summary {
  display: grid;
  grid-template-columns: 125px 50px 125px;
  gap: 10px;
}

.select__menu {
  z-index: 100 !important;
  position: relative;
}

.select-async div {
  z-index: 100 !important;
}

.FIE_canvas-container {
  min-height: 500px !important;
}

.SfxSelect-Label {
  margin-bottom: 0px !important;
  color: black;
}

.accordion-patient .accordion-button {
  padding: 5px;
}

.accordion-patient .accordion-body {
  padding: 10px;
}

.w-full {
  width: 100%;
}

.align-items-center {
  align-items: center !important;
}

.pr-4 {
  padding-right: 1.5rem!important;
}

.SfxModal-Container {
  max-width: 360px !important;
}

.bot-editor {
  margin-top: 25px !important;
}

.drag-container.fullscreen {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  height: 100%;
  margin: 0px !important;
}

.button-fullscreen {
  position: absolute;
  z-index: 1000;
  width: 132px;
  margin-top: 15px;
  margin-left: 95px;
  height: 33px;
  border-radius: 5px;
  background-color: #6879eb;
  color: white;
  border: 0px;
  padding: 0px 15px;
}

.button-drag {
  position: absolute;
  z-index: 1000;
  margin-top: 15px;
  margin-left: 240px;
  height: 33px;
  border-radius: 5px;
  background-color: #6879eb;
  color: white;
  border: 0px;
  padding: 0px 15px;
}

/* .FIE_canvas-container textarea {
  margin-top: 14px !important;
  margin-left: 17px !important;
} */

.drag-container .draw-box {
  height: 1000px !important;
}

.drag-container.fullscreen .draw-box {
  height: 100vh !important;
}

.drawtool-list {
  width: 180px;
  height: 90px !important;
}

.table-cell-whitespace .rdt_TableRow div {
  white-space: normal !important;
}

/* .sticky-table .rdt_TableHeadRow .rdt_TableCol:first-child,
.sticky-table .rdt_TableRow .rdt_TableCell:first-child {
  position: sticky;
  background-color: inherit;
  left: 0;
  z-index: 2;
  border-right: 1px solid #ddd;
} */

.CUSTOM-X-ICON {
  padding: 4px;
  display: inline-block;
  list-style-type: none;
  user-select: none;
}

.zoom-modal {
  max-width: 100% !important;
  padding: 8px 0px 8px 8px;
}

.zoom-modal .modal-content {
  height: 100%;
}

.zoom-modal .modal-body div {
  height: 100%;
}

.popover-filerobot .popover-arrow {
  display: none;
}

.force-default-cursor * {
  cursor: default !important;
}

.force-default-grab * {
  cursor: grab !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}