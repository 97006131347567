.client-profile-page {
  .page-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .sections-tabs {
      grid-gap: 14px;
      display: flex;
      flex-wrap: wrap;
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 225px;
        width: 225px;
        height: 80px;
        border-radius: 6px;
        padding: 8px;
        border: 1px solid #939393;
        background: #fff;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.3s linear 0s;
        &:hover {
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
        &.active-tab {
          color: #fff;
          border-color: #34556f;
          background: #34556f;
        }
      }
    }
    .drop-down-box {
      display: flex;
      flex-direction: column;
      .basic-single {
        margin-bottom: 0 !important;
      }
    }
    .date-selector-box {
      display: flex;
      align-items: center;
      gap: 10px;

      .input-group {
        margin-bottom: 0 !important;
      }
    }
    .range-1,
    .range-2 {
      width: 340px;
      display: flex;
      flex-direction: column;
    }
  }
  .content-block {
    .drop-down-box {
      display: flex;
      flex-direction: column;
      .basic-single {
        margin-bottom: 0 !important;
      }
    }
    .date-selector-box {
      display: flex;
      align-items: center;
      gap: 10px;

      .input-group {
        margin-bottom: 0 !important;
      }
    }
    .range-1,
    .range-2 {
      width: 380px;
      display: flex;
      flex-direction: column;
    }
    .client-growth-block {
      display: flex;
      flex-direction: column;
      .block-heading {
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
      .graphs-block {
        grid-gap: 20px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        .graph-item {
          width: 100%;
          // width: 540px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          border: 1px solid #000;
          border-radius: 8px;
          padding: 12px;

          .year-title {
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
            font-family: Work Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 120% */
          }
        }
      }
      .graphs-block1 {
        grid-gap: 20px;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        .graph-item {
          width: 100%;
          // width: 540px;
          display: flex;
          flex-direction: column;
          border: 1px solid #000;
          border-radius: 8px;
          padding: 12px;

          .year-title {
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
            font-family: Work Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 120% */
          }
        }
      }
    }
    .odd-index {
      border-right: 1px solid black; /* Adjust border style as needed */
    }
    .table-block {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      border: 1px solid #f1f1f1;
      .table-header {
        height: 90px;
        display: flex;
        align-items: center;
        background: #34556f;
        .table-col {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex: 4;
          height: 100%;
          border-right: 1px solid #56b5ff;
          overflow: hidden;
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 10px !important;
            height: 0px !important;
          }
          .col-title {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            flex: 1;
            height: 100%;
            min-width: 125px;
          }
          .sub-col {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            border-top: 1px solid #56b5ff;
            height: 100%;
            // min-width: 600px;
            // overflow: hidden;
            // overflow-y: auto;
            height: 100%;
            // min-width: 600px;
            overflow: scroll;
            &::-webkit-scrollbar {
              width: 0px !important;
              height: 0px !important;
            }
            .col-title {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              border-right: 1px solid #56b5ff;
              height: 100%;
              min-width: 125px;
              &:last-child {
                border-right: none;
              }
            }
          }
          &:first-child {
            flex: 0.7;
            .col-title {
              width: 100%;
              padding: 10px;
              justify-content: start;
            }
          }
          &:last-child {
            border-right: none;
          }
        }
      }
      .table-rows {
        height: 60px;
        display: flex;
        align-items: center;
        background: #f8f8f8;
        cursor: pointer;
        &:nth-child(even) {
          background: #fff;
        }
        .table-col {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex: 4;
          height: 100%;
          border-right: 1px solid #f1f1f1;
          overflow: hidden;
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 0px !important;
            height: 0px !important;
          }
          .col-title {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000000;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            flex: 1;
            height: 100%;
            min-width: 125px;
          }
          .sub-col {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            border-top: 1px solid #f1f1f1;
            height: 100%;
            // min-width: 600px;
            overflow: scroll;
            &::-webkit-scrollbar {
              width: 0px !important;
              height: 0px !important;
            }
            .col-title {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              border-right: 1px solid #f1f1f1;
              height: 100%;
              min-width: 125px;
              &:last-child {
                border-right: none;
              }
            }
          }
          &:first-child {
            flex: 0.7;
            .col-title {
              width: 100%;
              padding: 10px;
              justify-content: start;
            }
          }
          &:last-child {
            border-right: none;
          }
        }
      }
      .table-rows:last-child {
        .table-col .sub-col {
          &::-webkit-scrollbar {
            width: 100% !important;
            height: 5px !important;
          }
        }
      }
      .table-rows-footer {
        height: 60px;
        display: flex;
        align-items: center;
        background: #f8f8f8;

        .table-col {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex: 4;
          height: 100%;
          border-right: 1px solid #f1f1f1;
          .col-title {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000000;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            flex: 1;
            height: 100%;
            min-width: 125px;
          }
          .sub-col {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            border-top: 1px solid #f1f1f1;
            height: 100%;
            .col-title {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              border-right: 1px solid #f1f1f1;
              height: 100%;
              min-width: 125px;
              &:last-child {
                border-right: none;
              }
            }
          }
          &:first-child {
            flex: 0.7;
            .col-title {
              width: 100%;
              padding: 10px;
              justify-content: start;
            }
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .client-growth-section {
      min-height: 560px;
      padding: 12px;
      border-radius: 8px;
      background: #fff;
      margin: 30px;
    }
    .age-block {
      min-height: 560px;
      padding: 12px;
      border-radius: 8px;
      background: #fff;
      margin: 30px;
    }
  }
}

.mode-table-block {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid #f1f1f1;
  .table-header {
    height: 70px;
    display: flex;
    align-items: center;
    background: #34556f;
    .table-col {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 4;
      height: 100%;
      border-right: 1px solid #56b5ff;
      .col-title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        flex: 1;
        height: 100%;
        min-width: 125px;
      }
      &:first-child {
        flex: 0.6;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .table-rows {
    height: 40px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    &:nth-child(even) {
      background: #fff;
    }
    .table-col {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 4;
      height: 100%;
      border-right: 1px solid #f1f1f1;
      .col-title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        flex: 1;
        height: 100%;
        min-width: 125px;
      }
      &:first-child {
        flex: 0.6;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

.age-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.demographic-page-block {
  display: flex;
  flex-direction: column;
  .block-heading {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .graphs-block {
    grid-gap: 20px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    .graph-item {
      width: 100%;
      // width: 540px;
      min-height: 400px;
      display: flex;
      flex-direction: column;
      border: 1px solid #000;
      border-radius: 8px;
      padding: 12px;

      .year-title {
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        font-family: Work Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
      }
    }
    .pie-graph {
      > div {
        display: flex;
        justify-content: center;
      }
    }
  }
}
