.selected-user-container {
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	padding: 0;
	background: #ffffff;
}
.selected-user-heading {
	background: #34556f;
	width: 100%;
	color: white;
	padding: 20px 10px;
	border-radius: 4px 4px 0px 0px;
}
.selected-user-card {
	padding: 10px 10px;
	border-top: 1px solid #e9e9e9;
}
.roasting-view-table {
	overflow: auto;
}
.manual-table-header {
	color: #34556f;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
}
.manual-column {
	background: #ffffff;
	min-width: 150px;
	width: 150px;

	/* height: 53px; */
	padding: 8px 10px;

	border: 1px solid #e0e0e0;
}
.dx-scheduler-date-table-row {
	height: 75px;
}
.dx-scheduler-time-panel-row {
	height: 75px;
}
