.heading-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
}
.df-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.black-label-text {
  color: black;
  font-weight: bold;
  font-size: 19px;
  line-height: 21px;
}

.required::after {
  content: " *";
  color: black;
}

.green-rounded-button {
  border: none;
  background-color: #65de62;
  border-radius: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px 15px;
}
.brown-rounded-button {
  border: none;

  background-color: #dea562;
  border-radius: 15px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}
.red-rounded-button {
  border: none;

  background-color: #e7653d;
  border-radius: 15px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}
.conversion-rounded-button {
  border: none;

  background-color: #1c7a89;
  border-radius: 15px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}
.additional-rounded-button {
  border: none;

  background-color: #749be3;
  border-radius: 15px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}
.yellow-rounded-button {
  border: none;

  background-color: #dccd46;
  border-radius: 15px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}
.black-rounded-button {
  border: none;
  background-color: black;
  border-radius: 15px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}
.white-rounded-button {
  border: none;

  background-color: white;
  border-radius: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #34556f;
  padding: 5px 15px;
}
.white-rounded-button:hover {
  background-color: #34556f;
  color: white;
}
.theme-rounded-button {
  border: none;
  /* outline: none; */
  background-color: #34556f;
  border-radius: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px 15px;
}
.grey-rounded-button {
  border: none;
  background-color: #939393;
  border-radius: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px 15px;
}
.rounded-button {
  border: none;
  border-radius: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px 15px;
}
.error {
  color: red;
  font-size: 18px;
}

.bg-theme {
  background: #34556f;
  padding: 5px;
}
.hidePointer {
  pointer-events: none;
}

.open-animate {
  /* width: 350px; */
  width: 280px !important;
  transition: width 0s;
  background-color: white;
  margin-left: 10px;
  padding: 0 10px;
  /* min-width: 400px; */
}
.close-animate {
  width: 200px;
  transition: width 0s;
  background-color: white;
  margin-left: 10px;
  padding: 0 10px;
  /* min-width: 200px; */

  /* min-width: 370px; */
}
.dx-scheduler-header {
  display: none;
}

.form-button {
  width: fit-content;
  height: 45px;
  padding: 0 30px;
  border: none;
  /* Form Button Color */

  background: #62dea3;
  border-radius: 25px;
}
.dx-scheduler-cell-sizes-horizontal {
  width: 420px;
  /* padding-left: 100px; */
}
.dx-scheduler-appointment {
  left: 15px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar:horizontal {
  height: 6px;
}

/* * {
	scrollbar-width: thin;
} */
.grey-div {
  background: #f8f8f8;
  padding: 10px 15px;
}
.white-button {
  background-color: white;
  border: 2px solid #939393;
  border-radius: 10px;
  color: #939393;
}
.highlighted-container {
  background: #ffffff;

  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.grey-bg {
  background: #f7f7f7;
}
.down-arrow {
  box-sizing: border-box;
  height: 7px;
  width: 7px;
  border-style: solid;
  border-color: black;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}
/* hideCalender::-webkit-calendar-picker-indicator {
	content: "\f073";
	font-family: FontAwesome;
	color: white;
} */
.whiteCalender::after {
  content: "\f073";
  font-family: FontAwesome;
  color: white;
}
.themeInput .input-group {
  /* background-color: #34556f; */
  outline: none;
  border: 0px !important;
}
.white-bg {
  background: white;
}
.consent-container .css-b62m3t-container {
  z-index: 999;
}
