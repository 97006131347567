.grey {
	color: #939393;
}
.white {
	color: #ffffff;
}
.theme {
	color: #34556f;
}
.black {
	color: black;
}
.font-400-20 {
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
}
.font-400-16 {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}
.font-500-20 {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}
.font-600-28 {
	font-weight: 600;
	font-size: 28px;
	line-height: 32px;
}
.font-600-18 {
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
}
.font-600-24 {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
}
.white-600-24 {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: white;
}
.black-500-20 {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: black;
}
