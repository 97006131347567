.simple-input {
	outline: none;
	border: 0;
}
.simple-input :focus {
	outline: none;
	border: 0;
}
.hideArrows::-webkit-outer-spin-button,
.hideArrows::-webkit-inner-spin-button {
	display: none;
}
